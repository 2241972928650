.social-share{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0.5em 0;   
}

.social-share > * {
    margin-bottom: 1em;
}