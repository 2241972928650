@import "../styles/styles.scss";

ul.accordion{
      
    li{
      list-style: none;
      width: 95%;
      margin: 0.5em auto;
      padding: 0.3em;
      border-radius: 8px;
      box-shadow: $card-shadow;

      input[type='radio']{
        display:none;
      }

      label{
        @include font-size(18px, 24px);
        display: flex;
        align-items: center;
        padding:0.5em;
        font-weight:bold;
        cursor: pointer;
      }

      label::before{
        content: '+';
        @include font-size(18px, 24px);
        margin-right: 10px;
        margin-bottom: 0;
        font-weight:bold;
      }

      .content{
        max-height: 0;
        padding: 0;
        letter-spacing: 1px;
        overflow: hidden;
        transition: max-height 0.5s, padding 0.5s;

        p{
          @include font-size(18px, 24px);
          color: $MedDark;
          padding: 0 0.5em;
          margin: 0.3em 0.3em;
          line-height:1.1em;
          font-weight: 500;
          text-align: left;
        }
        
      }

      input[type='radio']:checked + label + .content{
        max-height: 600px;
      }

      input[type='radio']:checked + label::before{
        content: '-';
      }
      
    }
  }