html, body, ul, ol, li, form, fieldset, legend
{
	margin: 0;
	padding: 0;
}

h1, h2, h3, h4, h5, h6, p { margin-top: 0; }
fieldset,img { border: 0; }
li { list-style: none; }

*, *::before, *::after {
	box-sizing: border-box;
	// outline: 1px solid red;
}