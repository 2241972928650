@import './styles/styles.scss';

.exif {
	position: relative;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	background-color: $Light;
	box-shadow: 5px 5px 10px rgba(250, 250, 250, 0.3);

	.title {
		h1 {
			@include font-size(22px, 50px);
			width: 100%;
			font-weight: 700;
			line-height: 2em;
			color: $Light;
			background-color: $mainDark;
			text-align: center;
			box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
			margin-top: 2px;
		}

		.btn {
			font-size: 12px;
			position: absolute;
			top: 5em;
			right: 0.5em;
			color: $Light;
			font-weight: 600;
			background-color: $mainColor;
			border-color: $Light;
			box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
		}
	}

	.image-picker {
		width: 100%;
		min-height: 76vh;
		background-color: $Light;
		text-align: center;

		h3 {
			@include font-size(20px, 28px);
			color: lighten($Dark, 20%);
			// margin-bottom: 0.8em;
		}

		input[type='file'] {
			display: none;
		}

		.social-share {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			margin: 0;
			margin-bottom: 0.5em;
		}

		.social-share > * {
			margin-bottom: 1em;
		}

		.label-container {
			padding: 0 2em;
			margin-bottom: 1em;

			label {
				@include font-size(20px, 28px);
				text-transform: uppercase;
				font-weight: 700;
				margin: 0;
				padding: 0.2em 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.image-container {
			width: 98%;
			margin: auto;

			img {
				width: 100%;
				max-width: 600px;
				max-height: 600px;
				object-fit: contain;
			}

			p {
				@include font-size(14px, 20px);
				// border: 2px solid $Dark;
				margin: 0.1em auto;
			}
		}

		.link {
			margin: 2em auto;
		}

		.data-container {
			// width: calc(100% + 3em);
			width: 100%;
			padding: 0.5rem;
			margin-bottom: 4em;
			display: flex;
			justify-content: left;
			overflow: hidden;
			// border: 2px solid red;

			p {
				@include font-size(10px, 18px);
				color: $Dark;
				height: 100%;
				border: none;
				text-align: left;
			}
		}
	}
	footer {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		color: $Light;
		background-color: $mainDark;
		text-align: center;
		padding: 0.5em 0;
		margin-bottom: 0;

		a {
			@include font-size(16px, 28px);
			margin: 0;
			text-decoration: none;
			cursor: pointer;
			color: $Light;

			&:hover {
				font-size: 1.1em;
			}
		}
		p {
			@include font-size(16px, 28px);
			margin: 0;
		}
	}
}
// FOR TABLETS & DESKTOP
@include mQ(768px) {
	.exif {
		.image-picker {
			margin: 0;

			.image-container {
				width: 100%;

				img {
					width: 95%;
					// box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
				}
			}
		}
	}
}
